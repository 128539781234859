@use "@angular/material" as mat;
@use "./typography" as typography;

@include mat.core();

$md-itupalette: (
  50 : #f1f8ff,
  100 : #dcefff,
  200 : #c4e4ff,
  300 : #acd9ff,
  400 : #9bd0ff,
  500 : #89c8ff,
  600 : #81c2ff,
  700 : #76bbff,
  800 : #6cb4ff,
  900 : #59a7ff,
  A100 : #76bbff,
  A200 : #6cb4ff,
  A400 : #59a7ff,
  A700 : #e4f0ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// Custom mat-select theme
@mixin mat-select-theme() {
  .mat-select-value {
    color: #59a7ff!important;
  }  
}

:root {
  @include mat.radio-overrides((
    label-text-size: 14px,
    label-text-color: #707070,
    state-layer-size: 35px,
  ));
}

$my-app-primary: mat.m2-define-palette($md-itupalette);
$my-app-accent: mat.m2-define-palette($md-itupalette, A200, A100, A400);
$my-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$my-app-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $my-app-primary,
        accent: $my-app-accent,
        warn: $my-app-warn,
      ),
    )
);

@include mat.all-component-typographies(typography.$my-app-typography);
@include mat.all-component-themes($my-app-theme);
@include mat-select-theme();
