@import "./sizes";
@import "./palette";

.auth-form {
  min-width: 320px;
  max-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

mat-form-field {
  width: 100%;
  margin: $formMargin;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  position: relative!important;
  top: -15px!important;
  display: block!important;
  padding: 0!important;
}

